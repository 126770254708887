import { Routes, Route} from "react-router-dom";
import Main from "./comps/Main";

function App() {
  return (
    <div>
        <Routes>
          <Route index element={<Main />} />
          <Route path="*" element={<Main />} />
        </Routes>
    </div>
  );
  // <Route path="academic" element={<AcademicWork />} />
}

export default App;
