import React from "react";
import freepik1 from "../imgs/freepik-1.png"
import freepik2 from "../imgs/freepik-2.png"

export default function Main(){
    return(<div className="sinem-main">
                <h2>Sinem und Fabian<br />Standesamtliche Hochzeit</h2>
                
                <img src={freepik2} />
                <br />
                <br />
                <p>Hallo ihr Lieben,<br />vielen Dank, dass Ihr eure Bilder mit uns teilen möchtet.</p>
                <br />
                <p>
                Mit einem Klick auf den Button "Bilder Hochladen" könnt Ihr das auch ganz einfach tun.
                Ihr werdet dann auf eine Seite weitergeleitet, wo ihr oben auf das "+ Neu" klicken könnt und dann mit
                "Datei Hochladen" Bilder hochladen könnt.
                </p>
                <br />
                <i>Ihr stimmt mit dem Hochladen zu, dass das Brautpaar die Bilder für seine Zwecke verwenden darf.</i>
                <br />
                <a href="https://cloud.lembros.de/s/Qys4k2WGbHiKb8J" target="_blank"><button>Bilder Hochladen</button></a>
            </div>
        )
}